import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background:'linear-gradient(45deg, white 30%, rgb(228,35,20) 70%)',
  },
  title: {
    marginLeft: "20px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const ApplBar = (props) => {
  const classes = useStyles();
  const handleDrawerToggle = () => {
    props.doOpen(!props.open);
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="black"
          aria-label="Open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large">
          <MenuIcon />
        </IconButton>
        <img src="favicon.ico" height={30} width={30}></img>
        <Typography variant="h6" className={classes.title} color={'black'}>
          E-slots Way by EGT
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default ApplBar;
